import logo from "../images/logo.svg";
import { useEffect, useState} from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";


export const Header = () => {
  const navigation = [
    { name: "Service Products", href: "", current: false },
    { name: "Work with us", href: "", current: false },
    { name: "Who we are", href: "", current: false },
  ];
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset'; 
    }

    return () => {
      document.body.style.overflow = 'unset'; 
    };
  }, [isOpen]);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const handleClick = () => {
    window.location.href = '/';
  };

  return (
    <Disclosure as="nav">
      {({ open }) => {
        return (<>
          <div className="bg-[F8F8F8] w-full mx-auto px-2 sm:px-6 lg:px-40">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button onClick={() => setIsOpen(!isOpen)}  className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex flex-shrink-0 items-center cursor-pointer" onClick={handleClick}>
                <img className="h-10 w-auto " src={logo} alt="Your Company" />
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-end">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-500 hover:bg-gray-700 hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isOpen && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10 blur-overlay"></div>
            )}

          <Disclosure.Panel className="sm:hidden fixed right-0 z-10">
            <motion.div 
            initial={{
              opacity:0,
              x:100
            }}
            animate={{
              opacity: 1,
              x:0
            }}
            transition={{
              delay: 0.4,
              x: {
                type:"spring",
                stiffness: 60
              },
              opacity: { duration: 0.2 },
              duration: 1
            }}
            className="bg-blue-700 w-[243px] h-screen space-y-1 px-2 pb-3 pt-2 rounded-xl">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-500"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </motion.div>
          </Disclosure.Panel>
        </>)
      }}
    </Disclosure>
  );
};
