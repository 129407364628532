import { Footer } from "./Footer";

const TermsAndConditions = () => {
    return (
      <div>
        <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 sm:py-8 md:py-10 ">
        <h4 className="text-center text-xs sm:text-sm p-2 mb-2">Last Updated: 24 September, 2024</h4>  
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 sm:mb-12 md:mb-16 text-center">Terms and Conditions</h1>
        
        <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">1. Introduction</h2>
          <p className="text-sm sm:text-base mb-2">Welcome to ENTNT ("we," "our," or "us"). By accessing and using our website, you agree to comply with these terms and conditions. If you disagree with any part of these terms, please do not use our website.
          </p>
        </div>
        <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">2. Use of Website</h2>
          <p className="text-sm sm:text-base mb-2">You must be at least 13 years of age (or older if required by relevant jurisdiction to comply with applicable laws) to use this website. By using this website and agreeing to these terms, you warrant and represent that you are at least 13 years of age (or older if required by relevant jurisdiction to comply with applicable laws).
          The content on this website is provided for general information purposes only. It is subject to change without notice.
          </p>
        </div>
        
        <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">3. Intellectual Property</h2>
          <p className="text-sm sm:text-base mb-2">All intellectual property rights, including but not limited to copyrights, trademarks, and patents, in the content of this website are owned by or licensed to us.
          You may not republish, sell, rent or sub-license any material from this website without our prior written consent.
          </p>
        </div>
  
        <div className="mb-6 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">4. Data Privacy</h2>
          <p className="text-sm sm:text-base mb-2">Our Privacy Policy governs your use of this website. By using this website, you consent to the processing of your personal data as outlined in our Privacy Policy. 
          </p>
        </div>
  
        <div className="mb-6 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">5. Limited Representations and Warranties</h2>
          <p className="text-sm sm:text-base mb-2">While we strive to ensure that the information on this website is accurate, we make no representations or warranties of any kind, express or implied, regarding the accuracy, reliability or availability of the website's content.</p>
        </div>
  
        <div className="mb-6 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">6. Disclaimer of Representations and Warranties</h2>
          <p className="text-sm sm:text-base mb-2">The use of this website is at your own risk. ENTNT disclaims all representations and warranties, whether express or implied, including but not limited to implied representations and warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. We do not guarantee that our website or services will be free from errors or uninterrupted.</p>
        </div>
  
        <div className="mb-6 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">7. Limitations of Liability</h2>
          <p className="text-sm sm:text-base mb-2">We will not be liable for any direct, indirect, special or consequential loss arising out of or in connection with the use of or inability to use this website.</p>
        </div>
  
        <div className="mb-6 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">8. Third-Party Links</h2>
          <p className="text-sm sm:text-base mb-2">This website may include links to other websites. These links are provided for your convenience to provide further information. We have no responsibility for the content of linked websites.</p>
        </div>
  
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">9. Governing Law</h2>
          <p className="text-sm sm:text-base mb-2">These terms and conditions are governed by and construed in accordance with the laws of the United Arab Emirates as applied in the Emirate of Dubai and to the extent applicable, the Meydan Free Zone, without regard to its conflict of law principles.</p>
        </div>
  
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">10. Modification and Termination</h2>
          <p className="text-sm sm:text-base mb-2">ENTNT reserves the right to modify, suspend or terminate this website or any part of it without notice at any time. You are accountable to regularly review these terms and conditions to ensure you are aware of any changes.</p>
        </div>
  
        <div className="bg-white shadow-md rounded-lg p-4 sm:p-6 mb-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-3">11. How to contact us</h2>
          <p className="text-sm sm:text-base">If you have any questions about our terms and conditions, please contact us at contact@en-tnt.com.</p>
        </div>
      </div>
      <Footer/>
      </div>
    );
  };
  
  export default TermsAndConditions;