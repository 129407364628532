import React, { useState } from "react";
import image from "../images/bg1.png";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import Group from "../images/Group.png";
import arrow from "../images/arrow.svg";
import Shipping from "../images/Shipping.svg";
import Property from "../images/Property.svg";
import Software from "../images/Software.svg";
import Ellipse from "../images/Ellipse 4.svg";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Footer } from "./Footer";

export const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const items = [
    {
      title1: "Digital",
      title2: "Expansion",

      content:
        "Optimise your workforce with our comprehensive solutions that includes remote services, products and a global partner network.\n\nFrom end-to-end talent and project management to our simplified billing, explore how our offerings can lead to high-performance teams and exceptional results",
    },
    {
      title1: "BEYOND",
      title2: "BORDERS",
      subtitle: "Navigating Remote Teams",
      content:
        "From finding reliable providers to ensuring compliance: a high-level introductory guide on how to source offshore service providers, integrate and manage remote teams and address compliance concerns for successful remote work",
    },
    {
      title1: "REMOTE",
      title2: "STACK",
      subtitle: "Discover how our AI co-pilots enhance workflows",
      content:
        "Our recruitment system identifies top candidates and our secure workspace and remote operations ensure compliance and data protection",
    },
    {
      title1: "OPERATIONAL",
      title2: "SUPPORT",
      subtitle:
        "Simplified operations with skilled management and strategic hiring",
      content:
        "Experience hassle-free day-to-day oversight, dedicated project managers and a unique approach to recruitment that provides high-performing hires",
    },
  ];
  const showItem = (index: number) => {
    setCurrentIndex(index);
  };

  const nextItem = () => {
    const nextIndex = (currentIndex + 1) % items.length;
    showItem(nextIndex);
  };
  return (
      <div className="pt-1">
        <div className="px-2.5 md:px-16 lg:px-28 md:pb-20 pb-8  ">
          <motion.div
          initial = {{
            y:100,
            opacity: 0 
          }}
          animate={{
            y:0,
            opacity: 1
          }}
          transition = {{
            duration: 0.5,
            x: {
              type: "spring",
              stiffness: 60
            },
            opacity: {
              duration: 1
            }
          }}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="box-border md:px-20 md:py-20 rounded-tr-3xl rounded-tl-3xl mt-16 md:mt-32 h-[550px] md:h-[550px]"
          >
            <motion.div 
            className="absolute top-24 md:static md:0 px-7 md:-mt-40 ml-7 mr-10 md:mr-0 md:ml-0 bg-[#05004B] bg-opacity-90 rounded-3xl max-w-[600px] lg:p-16 lg:pt-12 lg:pb-11 md:p-14 p-6 leading-5 transform transition-position duration-500 ease-in-out"
              initial = {{
                y:100,
                opacity: 0 
              }}
              animate={{
                y:0,
                opacity: 1
              }}
              transition = {{
                delay: 0.1,
                x: {
                  type: "spring",
                  stiffness: 60
                },
                opacity: {
                  duration: 1
                },
                ease: "easeIn",
                duration: 0.1
              }}
            >
              <p className="text-white md:text-5xl text-3xl font-bold">
                Remote Teams
              </p>
              <p className="text-indigo-600 md:text-5xl text-3xl font-bold">
                Made Easy
              </p>
              <p className="mt-8 text-sm md:text-[18px] text-white">
                ENTNT is a complete workforce solutions provider, offering an
                integrated remote service that combines recruitment, training and
                management of personnel alongside customized software and AI to
                allow businesses to optimize or grow their operations.
                <br />
                <br /> With a personalized, research-intensive focus on the global
                labor market,
                <br />
              </p>
              <p className="text-indigo-200 font-medium md:text-xl text-md">
                "we offer skilled professionals and technological aids at the scale
                and level of commitment that you require".
              </p>
              <br />
              <p className="md:text-[18px] text-sm text-white">
                We believe in maintaining a human-centric approach to staffing which
                fosters strong connections.
              </p>
            </motion.div>
          </motion.div>
      {/* section 2 */}
      <div className="flex md:flex-row flex-col mt-3 md:space-x-3 md:space-y-0 space-y-3">
        <motion.div 
        initial={{
          opacity:0,
          x:100
        }}
        whileInView={{
          opacity: 1,
          x:0
        }}
        transition={{
          delay: 0.4,
          x: {
            type:"spring",
            stiffness: 60
          },
          opacity: { duration: 0.5 },
          duration: 1
        }}
        className="bg-[#DAD7FF] px-16 md:px-14 md:py-10 py-8 md:w-1/2 ">
          <div className="flex md:space-x-3 space-x-2 md:mb-8 mb-4 md:flex-wrap">
            <p className="text-indigo-600 md:text-4xl text-3xl font-bold uppercase">
              our
            </p>
            <p className="text-black md:text-4xl text-3xl font-bold uppercase ml-0">
              Services
            </p>
          </div>
          <p className="text-md">
            We are a complete workforce solutions provider, offering an
            integrated remote service that combines recruitment, training and
            management of personnel alongside customized software and AI to
            allow businesses to optimize or grow their operations. <br /> <br />
            Combined with our own recruitment and corporate memory tools, we use
            the latest research and data management to help businesses with
            their resourcing, scaling and project requirements.
          </p>
          <img
            className="bg-[#CAC6FF] rounded-lg p-6 mt-8"
            src={Group}
            alt="Your Company"
          />
        </motion.div>

        <div
          id="controls-carousel"
          className="flex space-x-2 relative md:w-1/2 overflow-x-auto"
          data-carousel="static"
        >
          <motion.div 
          initial={{
            opacity:0,
            x:-100
          }}
          whileInView={{
            opacity: 1,
            x:0
          }}
          transition={{
            delay: 0.4,
            x: {
              type:"spring",
              stiffness: 60
            },
            opacity: { duration: 0.5 },
            duration: 1
          }}
          className="relative md:overflow-hidden flex md:space-x-0 space-x-3">
            {items.map((item, index) => (
              <motion.div
              initial={{
                opacity:0,
                x:100
              }}
              whileInView={{
                opacity: 1,
                x:0
              }}
              transition={{
                delay: 0.4,
                x: {
                  type:"spring",
                  stiffness: 60
                },
                opacity: { duration: 0.2 },
                duration: 1
              }}
                key={index}
                className={`${
                  index === currentIndex ? "" : "md:hidden"
                }  bg-[#C2BDFF] md:px-14 md:py-12 px-7 py-8 md:h-full w-[310px] md:w-full`}
                data-carousel-item
              >
                <div className="flex flex-col mb-8">
                  <p className="text-black md:text-4xl text-3xl font-bold uppercase">
                    {item.title1}
                  </p>

                  <p className="text-indigo-600 md:text-4xl text-3xl font-bold uppercase">
                    {item.title2}
                  </p>
                </div>
                <p className="text-[#3B31DE] text-lg font-medium my-4">
                  {item.subtitle}
                </p>
                <motion.p 
                initial={{
                  opacity:0,
                  x:100
                }}
                whileInView={{
                  opacity: 1,
                  x:0
                }}
                transition={{
                  delay: 0.4,
                  x: {
                    type:"spring",
                    stiffness: 60
                  },
                  opacity: { duration: 0.2 },
                  duration: 1
                }}
                className="text-lg font-medium leading-tight">
                  {item.content.split("\n\n").map((paragraph, i, arr) => (
                    <React.Fragment key={i}>
                      {i > 0 && <br />}
                      {paragraph}
                      {i < arr.length - 1 && <br />}{" "}
                    </React.Fragment>
                  ))}
                </motion.p>
              </motion.div>
            ))}
          </motion.div>
          <button
            type="button"
            className="bg-[#C2BDFF] items-center justify-center max-h-full p-10 md:p-5 cursor-pointer focus:outline-none hidden sm:block"
            data-carousel-next
            onClick={nextItem}
          >
            <img className="min-h-3 min-w-3" src={arrow} alt="Your Company" />
          </button>
        </div>
      </div>

      {/* section 3  */}
      <div 
      className="relative bg-[#03002D] mt-3 md:h-[620px] md:px-28 md:py-20 px-8 py-8">
        <motion.p
        initial={{
          opacity:0,
          x:-90
        }}
        whileInView={{
          opacity: 1,
          x:0
        }}
        transition={{
          delay: 1,
          x: {
            type:"spring",
            stiffness: 60
          },
          opacity: { duration: 1 },
          duration: 1
        }} 
        className="text-indigo-600 md:text-4xl text-2xl font-bold uppercase">
          Areas
        </motion.p>

        <motion.p 
        initial={{
          opacity:0,
          x:-50
        }}
        whileInView={{
          opacity: 1,
          x:0
        }}
        transition={{
          delay: 0.2,
          x: {
            type:"spring",
            stiffness: 60
          },
          opacity: { duration: 1 },
          duration: 0.5
        }}
        className="text-white md:text-4xl text-2xl font-bold uppercase">
          Of Operation
        </motion.p>

        <div className="md:absolute md:top-52 md:right-10 md:left-10 md:mt-0 mt-6 flex md:flex-row flex-col md:space-x-6 md:space-y-0 space-y-3">
          <motion.div 
          initial={{
            opacity:0,
            y:100
          }}
          whileInView={{
            opacity: 1,
            y:0
          }}
          transition={{
            delay: 0.5,
            y: {
              type:"spring",
              stiffness: 60
            },
            opacity: { duration: 1 },
            duration: 1
          }}
          className="flex flex-col-reverse bg-[rgb(213,211,255)] rounded-2xl bg-opacity-90 box-border">
            <div className="flex flex-col md:w-auto md:px-10 md:pt-8 p-7 pr-16 box-border">
              <p className="text-black text-2xl text-md font-bold ">Software</p>
              <p className="text-black text-2xl text-md font-normal">
                Services
              </p>
              <p className="text-md text-left mt-3 w-full">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <a href="mailto:contact@en-tnt.com">
                <button
                  type="button"
                  className=" mt-8  w-3/4 inline-flex md:w-full justify-center rounded-lg bg-indigo-600 px-4 py-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                >
                  Contact Us
                </button>
              </a>
            </div>
            <div className="overflow-hidden w-full rounded-xl h-[16.5rem]">
            <img
              className="w-full h-full object-cover transform transition duration-1000 ease-in-out hover:scale-110"
              src={img1}
              alt="Your Company"
            />
            </div>
          </motion.div>
          <motion.div 
          initial={{
            opacity:0,
            y:100
          }}
          whileInView={{
            opacity: 1,
            y:0
          }}
          transition={{
            delay: 0.5,
            y: {
              type:"spring",
              stiffness: 60
            },
            opacity: { duration: 1 },
            duration: 1
          }}
          className="flex flex-col-reverse bg-[#D5D3FF] rounded-2xl bg-opacity-90 box-border">
            <div className="flex flex-col md:w-auto md:px-10 md:pt-8 p-7 pr-16 box-border">
              <p className="text-black text-2xl text-md font-bold">
                General Office
              </p>
              <p className="text-black text-2xl text-md font-normal">
                Services
              </p>
              <p className="text-md text-left mt-3 w-full">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <a href="mailto:contact@en-tnt.com">
                <button
                  type="button"
                  className=" mt-8  w-3/4 inline-flex md:w-full justify-center rounded-lg bg-indigo-600 px-4 py-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                >
                  Contact Us
                </button>
              </a>
            </div>
            <div className="overflow-hidden w-full rounded-xl h-[16.5rem] ">
            <img
              className="w-full h-full object-cover transform transition duration-1000 ease-in-out hover:scale-110"
              src={img2}
              alt="Your Company"
            />
            </div>
          </motion.div>
        </div>
      </div>

      {/* section 4 */}
      <div className="md:mt-52 mt-16 md:mb-10 mb-8">
        <p className="text-black md:text-4xl text-2xl text-md font-bold uppercase text-center">
          Areas of expertise
        </p>
        <p className="text-md text-center font-normal mt-4 w-full px-5">
          We're a dynamic group of individuals who are passionate about what we
          do and <br /> dedicated to delivering the best results for our
          clients.
        </p>
      </div>

      <div className="flex flex-col lg:flex-row md:justify-center md:space-x-3 w-full">
        <motion.div
        initial={{
          opacity:0,
          x:100
        }}
        whileInView={{
          opacity: 1,
          x:0
        }}
        transition={{
          delay: 0.4,
          x: {
            type:"spring",
            stiffness: 60
          },
          opacity: { duration: 0.2 },
          duration: 1
        }}
        className=" bg-[#F6F7F8] md:px-24 md:my-10 md:py-12 px-7 py-8 flex md:flex-col md:items-center rounded-lg md:space-x-0 space-x-12 z-10">
          <img className="" src={Shipping} alt="Your Company" />
          <div className="flex flex-col align-left">
            <div className="md:mt-12 flex md:flex-col items-center md:space-x-0 space-x-1">
              <p className="text-black md:text-2xl text-xl font-bold ">
                Shipping
              </p>
              <p className="text-black md:text-2xl text-xl font-normal">
                Technology
              </p>
            </div>
            <button
              type="button"
              className="md:mt-20 mt-3 text-lg text-indigo-600 font-medium text-left md:text-center"
            >
              Contact Us
            </button>
          </div>
        </motion.div>

        <motion.div 
        initial={{
          opacity:0,
          scale:0.5
        }}
        whileInView={{
          opacity: 1,
          scale: 1
        }}
        transition={{
          delay: 0.4,
          scale: {
            type:"spring",
            stiffness: 60
          },
          opacity: { duration: 0.5 },
          duration: 1.2
        }}
        className=" bg-[#F6F7F8] md:px-24 mt-3 md:my-10 md:py-12 px-7 py-10 flex md:flex-col md:items-center rounded-lg md:space-x-0 space-x-8 z-10">
          <img className="h-16" src={Property} alt="Your Company" />
          <div className="flex flex-col align-left">
            <div className="md:mt-12 flex md:flex-col items-center md:space-x-0 space-x-1">
              <p className="text-black md:text-2xl text-xl font-bold ">
                Property
              </p>
              <p className="text-black md:text-2xl text-xl font-normal">
                Management
              </p>
            </div>
            <button
              type="button"
              className="md:mt-20 mt-3 text-lg text-indigo-600 font-medium text-left md:text-center"
            >
              Contact Us
            </button>
          </div>
        </motion.div>
        <motion.div
         initial={{
          opacity:0,
          x:-100
        }}
        whileInView={{
          opacity: 1,
          x:0
        }}
        transition={{
          delay: 0.4,
          x: {
            type:"spring",
            stiffness: 60
          },
          opacity: { duration: 0.2 },
          duration: 1
        }}
         className=" bg-[#F6F7F8] md:px-24 mt-3 md:my-10 md:py-12 px-7 py-10 flex md:flex-col md:items-center rounded-lg md:space-x-0 space-x-4 z-10">
          <img className="" src={Software} alt="Your Company" />
          <div className="flex flex-col align-left">
            <div className="md:mt-12 flex md:flex-col items-center md:space-x-0 space-x-1">
              <p className="text-black md:text-2xl text-xl font-bold ">
                Software
              </p>
              <p className="text-black md:text-2xl text-xl font-normal">
                Development
              </p>
            </div>
            <button
              type="button"
              className="md:mt-20 mt-3 text-lg text-indigo-600 font-medium text-left md:text-center"
            >
              Contact Us
            </button>
          </div>
        </motion.div>
      </div>
      <div className="flex md:justify-center overflow-x-clip ">
        <img
          src={Ellipse}
          alt="image"
          className=" md:-mt-80 z-0 h-80 md:mr-4 ml-64 md:ml-0 -mt-96"
        />
      </div>
      {/* section 5 */}
      <div className="flex flex-col md:flex-row bg-[#03002D] md:mt-20 mt-10">
        <div className="flex flex-col justify-left space-y-8 md:pl-16 md:pr-4 md:py-16 md:w-2/5 px-7 py-8">
          <div className="flex md:flex-col md:space-x-0 space-x-2">
            {" "}
            <motion.p
            initial={{
              opacity:0,
              x:-40
            }}
            whileInView={{
              opacity: 1,
              x:0
            }}
            transition={{
              delay: 0.4,
              x: {
                type:"spring",
                stiffness: 60
              },
              opacity: { duration: 0.2 },
              duration: 1
            }}
            className="text-indigo-600 md:text-4xl text-2xl font-bold uppercase">
              Work
            </motion.p>
            <motion.p
            initial={{
              opacity:0,
              x:-50
            }}
            whileInView={{
              opacity: 1,
              x:0
            }}
            transition={{
              delay: 0.4,
              x: {
                type:"spring",
                stiffness: 60
              },
              opacity: { duration: 0.2 },
              duration: 1
            }} 
            className="text-white md:text-4xl text-2xl font-bold uppercase">
              with us
            </motion.p>
          </div>
          <motion.p 
          initial={{
            opacity:0,
            x:-60
          }}
          whileInView={{
            opacity: 1,
            x:0
          }}
          transition={{
            delay: 0.4,
            x: {
              type:"spring",
              stiffness: 60
            },
            opacity: { duration: 0.2 },
            duration: 1
          }}
          className="text-[#B3AEFF] md:text-lg text-md font-medium">
            Join us as we transform <br />
            remote work!
          </motion.p>
          <motion.p 
          initial={{
            opacity:0,
            x:-70
          }}
          whileInView={{
            opacity: 1,
            x:0
          }}
          transition={{
            delay: 0.4,
            x: {
              type:"spring",
              stiffness: 60
            },
            opacity: { duration: 0.2 },
            duration: 1
          }}
          className="text-white text-left">
            With an ongoing mandate to a large New York property management,
            ENTNT is actively engaged in both broadening its service portfolio
            and expanding its clientele across diverse industries.
          </motion.p>
          <div className="flex flex-col lg:flex-row items-center space-y-2 lg:space-x-5 lg:space-y-0">
            <button className="text-white max-w-fit lg:w-full font-medium flex items-center bg-indigo-600 p-2 px-3 md:px-4 rounded-2xl">
              Open Roles
              <ArrowLongRightIcon
                className="h-6 w-6 ml-2 text-white"
                aria-hidden="true"
              />
            </button>
            <button className="text-white max-w-fit lg:w-full font-medium flex items-center bg-indigo-600 p-2 px-3 md:px-4 rounded-2xl">
              Meet our leadership
              <ArrowLongRightIcon
                className="h-6 w-6 ml-2 text-white"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <div className="border-2 border-[#847CFF] rounded-xl md:mx-16 md:my-10 md:p-10 mx-7 px-7 py-7 mb-7 bg-[#05005D] bg-opacity-25 md:w-3/5">
          <motion.p 
          initial={{
            opacity:0,
            y:80
          }}
          whileInView={{
            opacity: 1,
            y:0
          }}
          transition={{
            delay: 0.4,
            y: {
              type:"spring",
              stiffness: 60
            },
            opacity: { duration: 0.5 },
            duration: 1
          }}
          className="text-white text-left">
            Our founding team comprises of cosmopolitan individuals with a
            background in shipping, software, business and law and credentials
            from top-tier employers, including former Microsoft engineers. ENTNT
            is well capitalized from its founders, allowing it to operate as an
            independent company with the ability to steadfastly uphold its
            strategy and values, unencumbered by compromises from external
            funding partners. <br />
            <br /> As a fully remote company and with a diverse ethos, we select
            top talents worldwide based on competency. We prioritize individuals
            who exemplify respect for colleagues, collaborate toward shared
            objectives and commit to deliver excellence in their work
          </motion.p>
        </div>
       </div>
      </div>
      <Footer/>
    </div>
  );
};
