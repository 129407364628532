import React, { useEffect } from 'react';
import { Footer } from './Footer';

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 sm:py-8 md:py-10 ">
      <h4 className="text-center text-xs sm:text-sm p-2 mb-2">Last Updated: 24 September, 2024</h4>  
      <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 sm:mb-12 md:mb-16 text-center">Privacy Policy</h1>
      <p className="mb-6 sm:mb-8 text-sm sm:text-base">ENTENTE SOLUTION L.L.C-FZ ("we," "our," or "us") is committed to protecting the privacy of individuals who visit our website and use our services. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information. By accessing our website or using our services, you agree to the terms of this Privacy Policy.</p>
      
      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">1. Information We Collect</h2>
        <p className="text-sm sm:text-base mb-2">We may collect various types of personal information from you, including but not limited to:</p>
        <ul className="list-disc pl-5 sm:pl-6 mt-2 text-sm sm:text-base">
          <li><span className="font-medium">Contact Information</span>: First name, last name, title, email address, phone number and other relevant contact details.</li>
          <li><span className="font-medium">Demographic Information</span>: Age, date of birth, gender and other relevant demographic details.</li>
          <li><span className="font-medium">Usage Data</span>: Information about how you use our website, including pages visited, time spent and interactions.</li>
          <li><span className="font-medium">Cookies and Tracking Technologies</span>: We may use cookies and similar technologies to enhance your browsing experience and collect usage data.</li>
        </ul>
      </div>

      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">2. How We Use Your Information</h2>
        <p className="text-sm sm:text-base mb-2">We may use the collected information for the following purposes:</p>
        <ul className="list-disc pl-5 sm:pl-6 mt-2 text-sm sm:text-base">
          <li><span className=" font-medium">Providing and Improving Services</span>: To provide our services to you and improve their quality.</li>
          <li><span className=" font-medium">Communication</span>: To respond to your inquiries, send you updates, and provide relevant information.</li>
          <li><span className=" font-medium">Personalization</span>: To customize your experience on our website and tailor content to your preferences.</li>
          <li><span className=" font-medium">Analytics</span>: To analyze usage patterns and improve our website's functionality.</li>
          <li><span className=" font-medium">Legal Compliance</span>: To comply with applicable laws and regulations.</li>
        </ul>
      </div>

      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">3. Collection and Use for Analytical and Statistical Purposes</h2>
        <p className="text-sm sm:text-base mb-2">We may collect, store, and process your personal information for analytical, statistical and research purposes. This may include:</p>
        <ul className="list-disc pl-5 sm:pl-6 mt-2 text-sm sm:text-base">
          <li>Conducting internal reporting and statistical analysis to improve our business operations.</li>
          <li>Using anonymized or pseudonymized data to evaluate user trends, monitor the performance of our website and assess the effectiveness of our services.</li>
          <li>Retaining such data for a period consistent with applicable laws or for as long as reasonably necessary to fulfill these purposes.</li>
        </ul>
      </div>

      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">4. Disclosure of Your Information</h2>
        <p className="text-sm sm:text-base mb-2">We may share your information with:</p>
        <ul className="list-disc pl-5 sm:pl-6 mt-2 text-sm sm:text-base">
          <li><span className=" font-medium">Service Providers</span>: Third-party vendors who assist us in providing our services.</li>
          <li><span className=" font-medium">Legal Authorities</span>: When required by law or to protect our rights.</li>
          <li><span className=" font-medium">Business Transfers</span>: In connection with a merger, acquisition, or sale of assets.</li>
        </ul>
      </div>

      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">5. Your Choices</h2>
        <ul className="list-disc pl-5 sm:pl-6 mt-2 text-sm sm:text-base">
          <li>You may update or correct your personal information by contacting us.</li>
          <li>You can opt out of receiving promotional communications from us by contacting us.</li>
          <li>You can disable cookies or set your browser to alert you when cookies are being sent.</li>
        </ul>
      </div>

      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">6. Security</h2>
        <p className="text-sm sm:text-base">We take appropriate measures to safeguard your personal information. However, no method of transmission over the internet or electronic storage is completely secure.</p>
      </div>

      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">7. Links to Third-Party Websites</h2>
        <p className="text-sm sm:text-base">Our website may contain links to third-party websites. We do not control these third-party websites and are not responsible for their privacy practices. We encourage you to review their policies and privacy statements.</p>
      </div>

      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">8. Children's Privacy</h2>
        <p className="text-sm sm:text-base">Our services are not intended for individuals under the age of 13 (or older if required by relevant jurisdiction to comply with applicable laws). We do not knowingly collect personal information from children. If you are under 13 years of age (or older if required by relevant jurisdiction to comply with applicable laws), please refrain from downloading or using our services. If you suspect that we have collected personal information about a child under the age of 13, please contact us.</p>
      </div>
      
      <div className="mb-6 bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">9. Changes to this Privacy Policy</h2>
        <p className="text-sm sm:text-base">We reserve the right to update this Privacy Policy. Any changes will be effective upon posting the revised policy and updating the date of this Privacy Policy on our website.</p>
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">10. How to contact us</h2>
        <p className="text-sm sm:text-base">If you have any questions about this Privacy Policy, please contact us at contact@entnt.in.</p>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default PrivacyPolicy;