import { useNavigate } from "react-router-dom";

const navigation = [
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/entnt",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ];
  
  export function Footer() {

    const navigate = useNavigate();

    const handlePrivacyClick = () => {
        navigate("/privacy-policy");
        window.scrollTo(0, 0);
    }

    const handleTermsClick = () => {
            navigate("/terms-and-conditions");
            window.scrollTo(0, 0);
    }
    return (
      <footer>
        <div className="mx-auto max-w-7xl px-6 py-8 md:mt-14 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2 mb-2 hover:scale-105 cursor-pointer transition-transform ease-in-out duration-75">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-blue-600 rounded-full bg-zinc-300 p-3 shadow-inner"
                target="blank"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon
                  className="h-6 w-6 hover:text-entntblue"
                  aria-hidden="true"
                />
              </a>
            ))}
          </div>
          <div className="flex space-x-6 justify-between">
            <span
              onClick={handlePrivacyClick}
              className="text-xs leading-6 text-gray-800 hover:text-gray-900 text-center cursor-pointer"
            >
              Privacy Policy
            </span>
            <span
              onClick={handleTermsClick}
              className="text-xs leading-6 text-gray-800 hover:text-gray-900 text-center cursor-pointer"
            >
              Terms and Conditions
            </span>
          </div>
          <div className="mt-8 md:order-3 md:mt-0">
            <p className="text-center text-xs leading-5 text-gray-800 ">
              {`Copyright © ${new Date().getFullYear()} ENTNT | All Rights Reserved`}
            </p>
          </div>
        </div>
      </footer>
    );
  }
  